$(function () {
    initServiceCarousel();
});

$('header .nav-button').unbind().click(function () {
    $('#nav_wrapper nav').toggleClass('toggle');
});

function initServiceCarousel() {
    $('#social_carousel').slick({
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
}

let map;

function initMap() {
    let location = {lat: 52.188337, lng: 4.442748};
    map = new google.maps.Map(document.getElementById('map'), {
        center: location,
        zoom: 18
    });

    let marker = new google.maps.Marker({
        position: location,
        map: map,
        title: 'aAnkOp Kappers'
    });
}